import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import get from 'lodash/get'

const ProductTeaser = (props) => {
    return (
        <>
            {props.value.map((item, key) => {
                return (
                    <div key={key} className="product-teaser">
                        <Link to={"/products/" + item.slug}>
                            {({}.hasOwnProperty.call(item, 'images') && item.images) && <img className="img-fluid" alt={get(item, 'title', 'Product image')} src={get(item, 'images[0].file.url', '')} />}
                            {{}.hasOwnProperty.call(item, 'title') && <p>{item.title}</p>}
                            {/* {({}.hasOwnProperty.call(item, 'sku') && {}.hasOwnProperty.call(item, 'title')) &&
                                <div className="yotpo bottomLine"
                                    data-appkey="VOeNm6QW9NR7zIZbJqf6cHqVuQRIgbceeFCIY8aI"
                                    data-product-id={item.sku}
                                    data-name={item.title}>
                                </div>
                            } */}
                        </Link>
                    </div>
                )
            })}
        </>
    );
}

ProductTeaser.propTypes = {
    value: PropTypes.array
}

export default ProductTeaser