/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { get, first } from 'lodash'
import { graphql } from 'gatsby'
import { globalHistory } from "@reach/router"
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ProductTeaser from '../components/helpers/productTeaser'
import { getDataForLanguage } from '../utils/getDataForLanguage'

const ProductsPage = ({ data }) => {

    const allProducts = get(data, 'allContentfulProduct.edges', []);
    const products = getDataForLanguage(allProducts.map(item => item.node), process.env.COUNTRY);

    const dataForMeta = get(data, 'allContentfulSiteInformations.edges', []);
    const metaData = first(getDataForLanguage(dataForMeta.map(item => item.node), process.env.COUNTRY));

    let activeTab = get(globalHistory, "location.state.activeTab", 0);

    const [activeTabIndex, setActiveTabIndex] = useState(activeTab);

    useEffect(_=> {
        setActiveTabIndex(activeTab) 
    }, [activeTab])

    return (
        <Layout
            title={get(metaData, 'productsPageMetaTitle','')} 
            description={get(metaData, 'productsPageMetaDescription','')}
            pathName="/products"
        >
            <div className="products-container" id="maincontent">
                <Tabs selectedIndex={activeTabIndex}>
                    <div className="products-page products-page--tab container">
                        <TabList>
                            <Tab tabIndex={[0]} onFocus={()=> setActiveTabIndex(0)}>All</Tab>
                            <Tab tabIndex={[0]} onFocus={()=> setActiveTabIndex(1)}>Hand</Tab>
                            <Tab tabIndex={[0]} onFocus={()=> setActiveTabIndex(2)}>Foot</Tab>
                            <Tab tabIndex={[0]} onFocus={()=> setActiveTabIndex(3)}>Lip</Tab>
                            <Tab tabIndex={[0]} onFocus={()=> setActiveTabIndex(4)}>Body</Tab>
                        </TabList>
                    </div>
                    <div className="products-page products-page--tabpanel container">
                        <TabPanel>
                            <div className="row products-teaser__container">
                                {process.env.COUNTRY === "en-US" && <ProductTeaser value={products.filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} /> }
                                {process.env.COUNTRY === "fr-CA" && <ProductTeaser value={products.filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} /> }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row products-teaser__container">
                                {process.env.COUNTRY === "en-US" && <ProductTeaser value={products.filter(h => h.category == "Hand").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} /> }
                                {process.env.COUNTRY === "fr-CA" && <ProductTeaser value={products.filter(h => h.category == "Hand").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} /> }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row products-teaser__container">
                                {process.env.COUNTRY === "en-US" && <ProductTeaser value={products.filter(h => h.category == "Foot").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} /> }
                                {process.env.COUNTRY === "fr-CA" && <ProductTeaser value={products.filter(h => h.category == "Foot").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} /> }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row products-teaser__container">
                                {process.env.COUNTRY === "en-US" && <ProductTeaser value={products.filter(h => h.category == "Lip").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} /> }
                                {process.env.COUNTRY === "fr-CA" && <ProductTeaser value={products.filter(h => h.category == "Lip").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} /> }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row products-teaser__container">
                                {process.env.COUNTRY === "en-US" && <ProductTeaser value={products.filter(h => h.category == "Body").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} /> }
                                {process.env.COUNTRY === "fr-CA" && <ProductTeaser value={products.filter(h => h.category == "Body").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} /> }
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </Layout>
    )
}

ProductsPage.propTypes = {
    data: PropTypes.any,
    location: PropTypes.object
}

export default ProductsPage

export const pageQuery = graphql`
    query ProductsPageQuery {
        allContentfulProduct(sort: {fields: order}) {
            edges {
                node {
                    availability
                    title
                    sku
                    slug
                    category
                    images {
                        file {
                            url
                        }
                        title
                        node_locale
                        contentful_id
                    }
                    node_locale
                    contentful_id
                }
            }
        }
        allContentfulSiteInformations {
            edges {
                node {
                        productsPageMetaTitle
                        productsPageMetaDescription
                        node_locale
                        contentful_id
                    }
                }
            }
    }
`